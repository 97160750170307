jQuery( document ).ready(function($) {

    $('.apple-form').hide();
    $('.form').hide();
    $('.thanks').hide();

    $('.start-btn').on('click', function(e){
        e.preventDefault();
        $('.start').hide();
        $('#page-wrap').css('background-color', "#000");
        $('.form').fadeIn();
    })

    $('.rsvp').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.form').hide();
                $('.thanks').fadeIn();
                $('#page-wrap').css('background-color','#0008');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });
});